<script>
  export let color = "primary";
  export let classes = "";

  export let type = "div"
</script>
{#if type == "submit"}
<button type="submit" class="button button-{color} {classes}" on:click on:keyup>
  <slot />
</button>
{:else if type == "button"}
<button type="button" class="button button-{color} {classes}" on:click on:keyup>
  <slot />
</button>
{:else}
<div class="button button-{color} {classes}" on:click on:keyup>
  <slot />
</div>
{/if}

<style>
  .button {
    @apply inline-block;
    @apply shadow-primary-shadow;
    @apply rounded;
    @apply px-5;
    @apply py-3;
    @apply cursor-pointer;

    @apply transition-colors;
    @apply duration-150;
  }

  .button,
  .button :global(a),
  .button :global(button) {
    /* @apply font-medium; */
    @apply text-white;
    @apply select-none;
  }

  .button-primary {
    @apply bg-primary-main;
  }

  .button-primary:hover {
    @apply bg-primary-dark;
  }

  .button-secondary {
    @apply bg-secondary-main;
    text-shadow: 0px 0px 3px rgb(0 0 0 / 30%);
  }

  .button-secondary:hover {
    @apply bg-secondary-dark;
  }

  .button-primary-contrast {
    @apply bg-white;
    @apply border-primary-main;
    @apply text-primary-main;
  }

  .button-primary-contrast:hover {
    @apply bg-primary-light;
  }
</style>
